<template>
  <div>
    <b-sidebar
      id="sidebar-create-classes"
      ref="sidebar-create-classes"
      width="750px"
      no-close-on-backdrop
      no-close-on-esc
      shadow
      right
      bg-variant="white"
      backdrop
      :title="Object.entries(this.dataProp).length === 0 ? 'Cadastrar turma' : 'Editar turma'"
    >
      <div>
        <hr />
        <b-overlay :show="isLoadingStore" opacity="0.70" rounded="sm">
          <b-tabs content-class="mt-2" align="center">
            <b-tab active>
              <template #title>
                <span>{{dataProp.id == null ? 'Cadastrar' : 'Editar'}}</span>
              </template>
              <div>
                <b-form method="POST">
                  <validation-observer ref="simpleRules">
                  <b-form-input class="hidden" name="_token" :value="csrf" />
                    <b-form-group 
                      label="* Escola:" 
                      label-for="i-school-class"
                      class="pl-1 pr-1"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="escola"
                        rules="required"
                      >
                        <b-form-select
                          id="i-school-class"
                          :disabled="dataClass.disabled_school_id"
                          v-model="dataClass.school_id"
                          :options="schools"
                          value-field="id"
                          text-field="name"
                          class="form-control"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Nome:"
                      label-for="i-name-class"
                      class="pl-1 pr-1"
                    >
                    <validation-provider
                        #default="{ errors }"
                        name="nome"
                        rules="required"
                      >
                      <b-form-input
                        id="i-name-class"
                        placeholder="5º Série"
                        v-model="dataClass.name"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Série:"
                      label-for="i-series"
                      class="pl-1 pr-1"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="série"
                        rules="required"
                      >
                        <b-form-input
                          id="i-series"
                          placeholder="Ex: A"
                          v-model="dataClass.series"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Período.:"
                      label-for="i-time-course"
                      class="pl-1 pr-1"
                    >
                    <validation-provider
                        #default="{ errors }"
                        name="periodo"
                        rules="required"
                      >
                      <b-form-select
                          id="i-time-course"
                          v-model="dataClass.time_course"
                          :options="time_courses"
                          value-field="id"
                          text-field="name"
                          class="form-control"
                          :state="errors.length > 0 ? false:null"
                        />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Professor Regente:"
                      label-for="i-teachers"
                      class="pl-1 pr-1"
                    >
                    <validation-provider
                        #default="{ errors }"
                        name="professor"
                        rules="required"
                      >
                      <v-select
                        size="lg"
                        multiple
                        label="name"
                        :reduce="(option) => option.id"
                        v-model="dataClass.teachers"
                        :options="teachers"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                      label="Livros:"
                      label-for="i-books"
                      class="pl-1 pr-1"
                    >
                    <validation-provider
                        #default="{ errors }"
                        name="books"
                        rules="required"
                      >
                      <v-select
                        size="lg"
                        multiple
                        label="name"
                        :reduce="(option) => option.id"
                        v-model="dataClass.books"
                        :options="booksList"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-row style="margin-right: 0rem !important;">
                    <b-col class="mr-1 ml-1">
                      <b-button
                        variant="relief-primary"
                        @click="storeClass((value = 1))"
                        :disabled="dataClass.name == null"
                        block
                      >
                        {{this.dataClass.id == null ? 'Salvar' : 'Editar'}}
                      </b-button>
                    </b-col>
                    <b-col>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-danger"
                        @click="closeSidebar()"
                        block
                      >
                        Fechar
                      </b-button>
                    </b-col>
                  </b-row>
                  </validation-observer>
                </b-form>
              </div>
            </b-tab>
            <b-tab>
              <template #title>
                <span>Importar</span>
              </template>
              <div>
                <b-form method="POST">
                  <validation-observer ref="simpleRulesImport">
                    <b-form-input class="hidden" name="_token" :value="csrf" />
                    <b-form-group 
                      label="* Escola:" 
                      label-for="i-school-import-class"
                      class="pl-1 pr-1"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="escola"
                        rules="required"
                      >
                        <b-form-select
                          id="i-school-import-class"
                        :disabled="dataImport.disabled_school_id"

                          v-model="dataImport.school_id"
                          :options="schools"
                          value-field="id"
                          text-field="name"
                          class="form-control"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Arquivo:"
                      label-for="i-file"
                      class="pl-1 pr-1"
                    >
                    <validation-provider
                        #default="{ errors }"
                        name="arquivo"
                        rules="required"
                      >
                      <b-form-file
                        placeholder="Arquivo"
                        v-model="dataImport.file"
                        type="file"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        name="file"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    </b-form-group>
                    <b-row style="margin-right: 0rem !important;">
                      <b-col sm="5" class="ml-1">
                        <b-button
                          variant="relief-primary"
                          @click="importClasses()"
                          :disabled="dataImport.file == null"
                          block
                        >
                          Importar
                        </b-button>
                      </b-col>
                      <b-col>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-danger"
                        @click="closeSidebar()"
                        block
                      >
                        Cancelar
                      </b-button>
                    </b-col>
                  </b-row>
                  </validation-observer>
                  <b-link class="mt-2 d-block text-center" href="/alunos_por_turma.xlsx" target="_blank">
                    <feather-icon icon="DownloadIcon" class="mr-1" />Baixar arquivo de exemplo
                  </b-link>
                </b-form>
              </div>
            </b-tab>
          </b-tabs>
        </b-overlay>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
/* eslint-disable */
import { BButton, BSidebar, VBToggle} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapState, mapMutations } from "vuex";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {mask} from 'vue-the-mask'
import vSelect from 'vue-select'
import {
  required, 
  email
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  props: { 
    dataProp: {
      type: Object,
      default: () => {}
    },
  },
  components: {
    BButton,
    BSidebar,
    vSelect,
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
    mask,
  },
  data() {
    return {
      icon: null,
      dataImport: {
        school_id: null,
        disabled_school_id: false,
        file: null,
      },

      showSidebar: false,

      dataClass: {
        school_id: null,
        name: null,
        series: null,
        time_course: null,
        disabled_school_id: false,
        teachers: [],
        books: [],
      },
      time_courses: [
        { id: "MATUTINO", name: "MATUTINO" },
        { id: "VESPERTINO", name: "VESPERTINO" },
        { id: "NOTURNO", name: "NOTURNO" },
      ],
      isLoadingInep: false,
      isLoadingStore: false,
      csrf: document.head.querySelector('meta[name="csrf-token"]') ? document.head.querySelector('meta[name="csrf-token"]').content : '',
    }
  },
  computed: {
    ...mapState("companies", ["companies"]),
    ...mapState("schools", ["schools"]),
    ...mapState("books", ["booksList"]),
    ...mapState("teachers", ["teachers"]),
  },
  methods: {
    ...mapActions("classes", ["store", "update", "import"]),
    ...mapMutations("teachers", ["SET_TEACHERS"]),
    initValues(){
      this.dataClass = {
        school_id: null,
        name: null,
        series: null,
        time_course: { id: "MATUTINO", name: "MATUTINO" },
        teachers: [],
        books: [],
      }
      this.dataImport = {
        school_id: null,
        file: null,
      }
      this.$nextTick(() => this.$refs.simpleRules.reset());
    },
    importClasses(){
      this.$refs.simpleRulesImport.validate().then(success => {
        if (success) {
          this.isLoadingStore = true
          this.import(this.dataImport).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Importado com sucesso!',
                icon: 'ThumbsUpIcon',
                text: 'Operação executada com sucesso.',
                variant: 'success'
              }
            })
            this.dataImport.school_id = null;
            this.dataImport.file = null;
            this.$nextTick(() => this.$refs.simpleRulesImport.reset());
            //this.fetchClasses(); 
          }).finally(() => {
            this.$emit('fetch-details', { 
              school_id: this.dataImport.school_id,
            });
            this.isLoadingStore = false
            this.closeSidebar()
          })
        }
      });
    },
    storeClass(value) {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.isLoadingStore = true
          if (this.dataClass.id == null) { 
            let data = new Date()
            this.store({...this.dataClass, school_year: data.getFullYear()}).then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Cadastrado com sucesso!',
                  icon: 'ThumbsUpIcon',
                  text: 'Operação executada com sucesso.',
                  variant: 'success'
                }
              })
              this.dataClass.id = null;
              this.dataClass.school_id = null;
              this.dataClass.name = null;
              this.dataClass.cpf = null;
              this.dataClass.email = null;
              this.dataClass.teachers = null;
              this.dataClass.books = null;
              this.$nextTick(() => this.$refs.simpleRules.reset());
            })
            .finally(() => {
              this.$emit('fetch-details', { 
                school_id: this.dataClass.school_id,
              });
              this.isLoadingStore = false;
              this.closeSidebar()
            })
          } else {
            this.update(this.dataClass).then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Atualizado com sucesso!',
                  icon: 'ThumbsUpIcon',
                  text: 'Operação executada com sucesso.',
                  variant: 'info'
                }
              })

              this.dataClass.id = null;
              this.dataClass.school_id = null;
              this.dataClass.name = null;
              this.dataClass.cpf = null;
              this.dataClass.email = null;
              this.dataClass.teachers = null;
              this.dataClass.books = null;
              this.$nextTick(() => this.$refs.simpleRules.reset());
            })
            .finally(() => {
              this.$emit('fetch-details', { 
                school_id: this.dataClass.school_id,
              });
              this.isLoadingStore = false
              this.closeSidebar();
            })
          }
        }else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro de validação!',
              icon: 'AlertTriangleIcon',
              text: 'Por favor preencha todos os campos obrigatorios.',
              variant: 'danger'
            }
          })
        }
      })
    },

    fetchSchools(paginated = false, page = 1){
      this.isLoading = true
      this.$store
        .dispatch("schools/fetchSchools", {
          paginated: paginated,
          page: page,
          generic: this.search,
          per_page: this.perPage
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    fetchBooks(){
      this.isLoading = true
      this.$store
        .dispatch("books/fetchBooksShort", {
          paginate: false,
          type: 'student',
          page: 1,
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    fetchTeachers(){
      this.isLoading = true
      this.$store.dispatch("teachers/fetchTeachers", {
        school_id: this.$route.params.school_id,
          pagination: false,
          page: 1,
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    closeSidebar(){
      this.$refs['sidebar-create-classes'].hide()
    }
  },
  watch: {
  dataProp: {
    // the callback will be called immediately after the start of the observation
    immediate: true, 
    handler (val, oldVal) {
      if (!val) return; 
      this.initValues(); 
      if (Object.entries(this.dataProp).length === 0 || this.dataProp.id == null) { 
        let { id, school_id, name, cpf, email, time_course, series } = JSON.parse(JSON.stringify(this.dataProp));
        this.SET_TEACHERS(this.dataProp.teachers)
        if(school_id){
          this.dataClass.school_id = school_id;
          this.dataClass.disabled_school_id = true;
          this.dataImport.school_id = school_id;
          this.dataImport.disabled_school_id = true;
        }

      } else { 
        let { id, school_id, name, cpf, email, time_course, series, teachers , books} = JSON.parse(JSON.stringify(this.dataProp));
        if(school_id){
          this.dataClass.school_id = school_id;
          this.dataClass.disabled_school_id = true;
          this.dataImport.school_id = school_id;
          this.dataImport.disabled_school_id = true;
          teachers.forEach((value, index) => {
            this.dataClass.teachers.push(value.id)
          });
          this.SET_TEACHERS(this.dataProp.teachers_list)
        }
          this.dataClass.id = id;
          this.dataClass.name = name;
          this.dataClass.cpf = cpf;
          this.dataClass.email = email;
          this.dataClass.time_course = time_course;
          this.dataClass.series = series;
          books?.forEach((value, index) => {
            this.dataClass.books.push(value.id)
          });
      } 
    }
  }
  },
  created() {
    this.fetchSchools();
    this.fetchBooks();
    this.fetchTeachers();
  },
}
</script>
