<template>
  <b-card>
    <h2 class="mb-2">Dados da escola</h2>
    <b-overlay
      :show="isLoading"
      opacity="0.70"
      variant="transparent"
      rounded="sm"
    >
      <b-row>
        <!-- User Info: Left col -->
        <b-col
          cols="2"
          xl="1"
          class="d-flex justify-content-between flex-column"
        >
          <!-- User Avatar & Action Buttons -->
          <div class="d-flex justify-content-start">
            <b-avatar
              :src="require('@/assets/images/pages/professor.svg')"
              class="bg-avatar bg-color-avatar"
              size="80px"
              rounded
            />
          </div>
        </b-col>

        <!-- Right Col: Table -->
        <b-col lg="5">
          <table class="mt-2 mt-xl-0 w-98">
            <tr>
              <th class="pb-50">
                <feather-icon icon="CreditCardIcon" class="mr-75" />
                <span class="font-weight-bold">Empresa:</span>
              </th>
              <td class="pb-50 text-capitalize font-weight-bold">
                {{ schoolShow && schoolShow.company ? schoolShow.company.company_name : '' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="CreditCardIcon" class="mr-75" />
                <span class="font-weight-bold">Escola:</span>
              </th>
              <td class="pb-50 text-capitalize font-weight-bold">
                {{ schoolShow ? schoolShow.name : '' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="HomeIcon" class="mr-75" />
                <span class="font-weight-bold">INEP:</span>
              </th>
              <td class="pb-50 text-capitalize font-weight-bold">
                {{ schoolShow ? schoolShow.inep : '' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="ListIcon" class="mr-75" />
                <span class="font-weight-bold">Endereço:</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ schoolShow && schoolShow.address ? schoolShow.address.street : '' }},
                {{ schoolShow && schoolShow.address ? schoolShow.address.city : '' }} -
                {{ schoolShow && schoolShow.address ? schoolShow.address.state : '' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="PhoneIcon" class="mr-75" />
                <span class="font-weight-bold">Telefone:</span>
              </th>
              <td class="pb-50">
                {{ schoolShow && schoolShow.company ? schoolShow.company.phone : '' }}
              </td>
            </tr>
          </table>
        </b-col>
        <!-- Endereço -->
        <b-col>
          <table class="mt-2 mt-xl-0 w-98">
            <tr>
              <th class="pb-50">
                <feather-icon icon="HomeIcon" class="mr-75" />
                <span class="font-weight-bold">IDEB A.I.:</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ schoolShow && schoolShow.last_ideb ? schoolShow.last_ideb.ideb_ai : '' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="GlobeIcon" class="mr-75" />
                <span class="font-weight-bold">IDEB A.F.:</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ schoolShow && schoolShow.last_ideb ? schoolShow.last_ideb.ideb_af : '' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="CalendarIcon" class="mr-75" />
                <span class="font-weight-bold">Data de cadastro:</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ schoolShow && schoolShow.created_at | dateFormat }} 
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="CalendarIcon" class="mr-75" />
                <span class="font-weight-bold">Data da última atualização:</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ schoolShow && schoolShow.updated_at | dateFormat }} 
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
      <hr />

      <b-tabs content-class="mt-2">
        <!-- Turma -->
        <b-tab active>
          <template #title>
            <span>Professores</span>
          </template>
          <div>
            <b-row>
              <b-col>
                <h3 class="md-10 mt-2">Lista de professores</h3>
              </b-col>
              <b-col md="2 mt-2">
                <b-button
                  v-b-toggle.sidebar-create-teacher
                  size="sm"
                  block
                  variant="relief-primary"
                  @click="addNewTeacher"
                >
                  Novo Professor
                  <feather-icon icon="GridIcon" />
                </b-button>
              </b-col>
            </b-row>
            <b-table-simple
              striped
              responsive
              class="mt-2 text-center"
              id="tableDirector"
            >
              <b-thead>
                <b-tr class="text-center">
                  <b-th>Data cadastro</b-th>
                  <b-th>Nome</b-th>
                  <b-th>E-MAIL</b-th>
                  <b-th>USUÁRIO</b-th>
                  <b-th>AÇÂO</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr :key="indextr" v-for="(tr, indextr) in schoolShow.teachers">
                  <b-td>
                    {{ tr.created_at | dateFormat }}
                  </b-td>
                  <b-td>
                    {{ tr.name }}
                  </b-td>
                  <b-td v-if="tr.user== null">
                    Não informado
                  </b-td>
                  <b-td v-else>
                    {{ tr.user.email }}
                  </b-td>
                  <b-td v-if="tr.user== null">
                    Não informado
                  </b-td>
                  <b-td v-else>
                    {{ tr.user.username }}
                  </b-td>
                  <b-td class="text-center">
                    <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      variant="flat-dark"
                      class="btn-icon rounded-circle"
                      v-b-toggle.sidebar-create-teacher
                      @click="updateDetailTeacher(tr.id)"
                    >
                      <feather-icon icon="Edit2Icon" />
                    </b-button>

                    <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      variant="flat-dark"
                      class="btn-icon rounded-circle"
                      @click="updateDetailTeacher(tr.id, isDelete = true)"
                      v-b-modal.modal-delete-teacher
                    >
                      <feather-icon icon="Trash2Icon" />
                    </b-button>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <!-- Modal Delete -->
            <b-modal
              id="modal-delete-teacher"
              ok-variant="danger"
              modal-class="modal-danger"  
              ref="modal-delete-teacher"
              centered
              title="Deletar Professor"
            >
              <b-card-text  v-if="DeleteStatusAdmin != null">
                Por favor, confirme a exclusão antes de prosseguir. 
                <b-form-checkbox
                  id="checkbox-1"
                  @change="newDeleteStatusTeacher"
                  v-model="DeleteStatusTeacher"
                  name="checkbox-1"
                  value="accepted"
                >
                  Concordo em excluir este professor
                </b-form-checkbox>  
              </b-card-text>
              <template #modal-footer>
                <b-col>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="mt-2"
                    variant="outline-danger"
                    block
                    :disabled="DeleteStatusTeacher == false"
                    @click="deleteTeacher(sidebarTeacherData.id)"
                  >
                    Deletar professor
                  </b-button>
                </b-col>
              </template>
            </b-modal>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <span>Turmas</span>
          </template>
            <div>
              <b-row>
                <b-col>
                  <h3 class="md-10 mt-2">Lista Turmas</h3>
                </b-col>
                
                <b-col md="2 mt-2">
                  <b-button
                    v-b-toggle.sidebar-create-classes
                    size="sm"
                    block
                    variant="relief-primary"
                    @click="addNewClass"
                  >
                    Nova turma
                    <feather-icon icon="GridIcon" />
                  </b-button>
                </b-col>
              </b-row>
              <b-table-simple
                striped
                responsive
                class="mt-2 text-center"
                id="tableClass"
              >
                <b-thead>
                  <b-tr class="text-center">
                    <b-th>SÉRIE</b-th>
                    <b-th>TURMA</b-th>
                    <b-th>PERÍODO</b-th>
                    <b-th>Livro atual</b-th>
                    <b-th>AÇÂO</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr :key="indextr" v-for="(tr, indextr) in schoolShow.classes">
                    <b-td>
                      <a :href="'/turma/' + tr.id">
                        {{ tr.name }}
                      </a>
                    </b-td>
                    <b-td>
                      {{ tr.series }}
                    </b-td>
                    <b-td>
                      {{ tr.time_course }}
                    </b-td>
                    <b-td>
                      {{ tr.class_book && tr.class_book.book.name }}
                    </b-td>
                    <b-td class="text-center">
                      <b-button
                        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                        variant="flat-dark"
                        class="btn-icon rounded-circle"
                        :to="{ path: '/turma/' + tr.id}"
                      >
                        <feather-icon icon="EyeIcon" />
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                        variant="flat-dark"
                        class="btn-icon rounded-circle"
                        @click="updateClass(tr.id)"
                        v-b-toggle.sidebar-create-classes
                      >
                        <feather-icon icon="Edit2Icon" />
                      </b-button>

                      <b-button
                        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                        variant="flat-dark"
                        class="btn-icon rounded-circle"
                        @click="updateDetailClass(tr.id, isDelete = true)"
                        v-b-modal.modal-delete-class
                      >
                        <feather-icon icon="Trash2Icon" />
                      </b-button>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
              <!-- Modal Delete -->
              <b-modal
                id="modal-delete-class"
                ok-variant="danger"
                modal-class="modal-danger"  
                ref="modal-delete-class"
                centered
                title="Deletar Turma"
              >
                <b-card-text v-if="sidebarClassData != null">
                  Por favor, confirme a exclusão antes de prosseguir. 
                  <b-form-checkbox
                    id="checkbox-1"
                    @change="newDeleteStatusClass"
                    v-model="DeleteStatusClass"
                    name="checkbox-1"
                    value="accepted"
                  >
                    Concordo em excluir esta turma
                  </b-form-checkbox>  
                </b-card-text>
                <template #modal-footer>
                  <b-col>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      class="mt-2"
                      variant="outline-danger"
                      block
                      :disabled="DeleteStatusClass == false"
                      @click="deleteClass(sidebarClassData.id)"
                    >
                      Deletar Turma
                    </b-button>
                  </b-col>
                </template>
              </b-modal>
            </div>
        </b-tab>
        <!-- Diretores -->
        <b-tab>
          <template #title>
            <span>Diretores</span>
          </template>      
          <div>
            <b-row>
              <b-col>
                <h3 class="md-10 mt-2">Lista de diretores</h3>
              </b-col>
              <b-col md="2 mt-2">
                <b-button
                  v-b-toggle.sidebar-create-director
                  size="sm"
                  block
                  variant="relief-primary"
                  @click="addNewDirector"
                >
                  Novo Diretor
                  <feather-icon icon="GridIcon" />
                </b-button>
              </b-col>
            </b-row>
            <b-table-simple
              striped
              responsive
              class="mt-2 text-center"
              id="tableDirector"
            >
              <b-thead>
                <b-tr class="text-center">
                  <b-th>Data cadastro</b-th>
                  <b-th>DIRETOR(A)</b-th>
                  <b-th>E-MAIL</b-th>
                  <b-th>AÇÂO</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr :key="indextr" v-for="(tr, indextr) in schoolShow.directors">
                  <b-td>
                    {{ tr.created_at | dateFormat }}
                  </b-td>
                  <b-td>
                    {{ tr.name }}
                  </b-td>
                  <b-td>
                    {{ tr.email }}
                  </b-td>
                  <b-td class="text-center">
                    <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      variant="flat-dark"
                      class="btn-icon rounded-circle"
                      v-b-toggle.sidebar-create-director
                      @click="updateDetailDirector(tr.id)"
                    >
                      <feather-icon icon="Edit2Icon" />
                    </b-button>

                    <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      variant="flat-dark"
                      class="btn-icon rounded-circle"
                      @click="updateDetailDirector(tr.id, isDelete = true)"
                      v-b-modal.modal-delete-director
                    >
                      <feather-icon icon="Trash2Icon" />
                    </b-button>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <!-- Modal Delete -->
            <b-modal
              id="modal-delete-director"
              ok-variant="danger"
              modal-class="modal-danger"  
              ref="modal-delete-director"
              centered
              title="Deletar Diretor"
            >
              <b-card-text  v-if="sidebarDirectorData != null">
                Por favor, confirme a exclusão antes de prosseguir. 
                <b-form-checkbox
                  id="checkbox-1"
                  @change="newDeleteStatusDirector"
                  v-model="DeleteStatusDirector"
                  name="checkbox-1"
                  value="accepted"
                >
                  Concordo em excluir este diretor
                </b-form-checkbox>  
              </b-card-text>
              <template #modal-footer>
                <b-col>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="mt-2"
                    variant="outline-danger"
                    block
                    :disabled="DeleteStatusDirector == false"
                    @click="deleteDirector(sidebarDirectorData.id)"
                  >
                    Deletar diretor
                  </b-button>
                </b-col>
              </template>
            </b-modal>
          </div>
        </b-tab>
        <!-- Gestor da Escola -->
        <b-tab>
          <template #title>
            <span>Gestor Escolar</span>
          </template>
          <div>
            <b-row>
              <b-col>
                <h3 class="md-10 mt-2">Lista de gestores</h3>
              </b-col>
              <b-col md="2 mt-2">
                <b-button
                  v-b-toggle.sidebar-create-admin
                  size="sm"
                  block
                  variant="relief-primary"
                  @click="addNewAdmin"
                >
                  Novo Gestor
                  <feather-icon icon="GridIcon" />
                </b-button>
              </b-col>
            </b-row>
            <b-table-simple
              striped
              responsive
              class="mt-2 text-center"
              id="tableDirector"
            >
              <b-thead>
                <b-tr class="text-center">
                  <b-th>Data cadastro</b-th>
                  <b-th>Nome</b-th>
                  <b-th>Usuário de acesso</b-th>
                  <b-th>E-MAIL</b-th>
                  <b-th>AÇÂO</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr :key="indextr" v-for="(tr, indextr) in schoolShow.users">
                  <b-td>
                    {{ tr.created_at | dateFormat }}
                  </b-td>
                  <b-td>
                    {{ tr.name }}
                  </b-td>
                  <b-td v-if="tr.username == null">
                    Não informado
                  </b-td>
                  <b-td v-else>
                    {{ tr.username }}
                  </b-td>
                  <b-td>
                    {{ tr.email }}
                  </b-td>
                  <b-td class="text-center">
                    <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      variant="flat-dark"
                      class="btn-icon rounded-circle"
                      @click="updateDetailAdmin(tr.id)"
                       v-b-toggle.sidebar-create-admin
                    >
                      <feather-icon icon="Edit2Icon" />
                    </b-button>

                    <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      variant="flat-dark"
                      class="btn-icon rounded-circle"
                      @click="updateDetailSchoolAdmin(tr.id, isDelete = true)"
                      v-b-modal.modal-delete-school-admin
                    >
                      <feather-icon icon="Trash2Icon" />
                    </b-button>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <b-modal
              id="modal-delete-school-admin"
              ok-variant="danger"
              modal-class="modal-danger"  
              ref="modal-delete-school-admin"
              centered
              title="Deletar Gestor"
            >
              <b-card-text v-if="updateDetailSchoolAdmin != null">
                Por favor, confirme a exclusão antes de prosseguir. 
                <b-form-checkbox
                  id="checkbox-1"
                  @change="newDeleteStatusSchoolAdmin"
                  v-model="DeleteStatusSchoolAdmin"
                  name="checkbox-1"
                  value="accepted"
                >
                  Concordo em excluir este gestor
                </b-form-checkbox>  
              </b-card-text>
              <template #modal-footer>
                <b-col>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="mt-2"
                    variant="outline-danger"
                    block
                    :disabled="DeleteStatusSchoolAdmin == false"
                    @click="deleteSchoolAdmin(updateDetailSchoolAdmin.id)"
                  >
                    Deletar gestor
                  </b-button>
                </b-col>
              </template>
            </b-modal>
          </div>
        </b-tab>
        <!-- Professor -->
      </b-tabs>
    </b-overlay>
    <create-edit-director :key="count" :dataProp="sidebarDirectorData" @fetch-details="fetchDetails" />
    <create-edit-class :dataProp="sidebarClassData" @fetch-details="fetchDetails" />
    <create-edit-admin :dataProp="sidebarAdminData" @fetch-details="fetchDetails"/>
    <create-edit-teacher :dataProp="sidebarTeacherData" @fetch-details="fetchDetails"/>
  </b-card>
</template>
<script>
/* eslint-disable */
import { BCard } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import CreateEditDirector from "../directors/CreateEdit";
import CreateEditClass from "../classes/CreateEdit";
import CreateEditAdmin from "./CreateEditSchoolAdmin";
import CreateEditTeacher from "../teacher/CreateEdit";

export default {
  components: {
    CreateEditDirector,
    CreateEditClass,
    CreateEditAdmin,
    CreateEditTeacher,
    BCard,
    vSelect
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      search: null,
      perPage: 10,
      currentPage: 1,
      totalRows: null,
      isLoading: true,
      isLoadingStore: false,

      //teachers: [],

      sidebarDirectorData: {},
      sidebarClassData: {},
      sidebarAdminData: {},
      sidebarTeacherData: {},
      count: 0,
      countClass: 1,
      countAdmin: 2,
      countTeacher: 3,
      DeleteStatusClass: false,
      DeleteStatusDirector: false,
      DeleteStatusAdmin: false,
      DeleteStatusSchoolAdmin: false,
      DeleteStatusTeacher: false,

      csrf: document.head.querySelector('meta[name="csrf-token"]') ? document.head.querySelector('meta[name="csrf-token"]').content : '',

      perPageOptions: [
        { value: 10, text: "10" },
        { value: 15, text: "15" },
        { value: 20, text: "20" },
      ],

      optionsCategories: [
        { value: 'student', text: 'Aluno' },
        { value: 'teacher', text: 'Professor' }
      ],
    }
  },
  computed: {
    ...mapState("schools", ["schoolShow"]),
    ...mapState("classes", ["classes"]),
    ...mapState("directors", ["directors"]),
    ...mapState("teachers", ["teachers"])
  },
  created(){
    this.fetchDetails();
  },
  methods: {
    ...mapActions("schools", ["fetchSchools", "find"]),

    fetchDetails(paginated = false, page = 1){

      this.isLoading = true
      let school_ids = this.$route.params.school_id
      if(localStorage.getItem('school_id')){
        school_ids = localStorage.getItem('school_id')
      }
      this.$store.dispatch("schools/fetchDetails", {
          school_id: school_ids,
          paginate: paginated,
          page: page,
        })
        .then((response) => {
          //this.teachers = response.teachers
        })
        .finally(() => {
          this.isLoading = false
        });
    },
    updateClass(id){
      this.sidebarClassData = this.$store.getters['schools/getClassById'](id)
      this.sidebarClassData.teachers_list = this.teachers
    },

    updateDetailClass(id, isDelete = false){
      this.$store.dispatch("classes/fetchDetailsClass", {
        class_id: id
      })
      .then((response) => {
        if(isDelete == true){
          this.sidebarClassData.id = response.id
          this.sidebarClassData.name = response.name
        }else{
          //this.countClass++;
          this.sidebarClassData.id = response.id
          this.sidebarClassData.school_id = response.school_id
          this.sidebarClassData.name = response.name
          this.sidebarClassData.series = response.series
          this.sidebarClassData.time_course = response.time_course
        }
       
      }) 
      .finally(() => {
      })
    },


    updateDetailAdmin(id) {
      this.sidebarAdminData = {
        id: id
      }; 
      this.countAdmin++;
    },

    newDeleteStatusClass(value){
      this.DeleteStatusClass = value
    },

    deleteClass(id){
      this.isLoading = true;
      let data = id
      this.$store
        .dispatch("classes/delete", data)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Excluído com sucesso!',
              icon: 'Trash2Icon',
              text: 'Operação executada com sucesso.',
              variant: 'danger'
            }
          })
          this.isLoading = false
          this.DeleteStatusClass = false
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          // this.sidebarClassData = {}
          this.fetchDetails()
          this.$refs['modal-delete-class'].hide()
        })
    },

    updateDetailDirector(id, isDelete = false){
      this.$store.dispatch("directors/fetchDetails", {
        director_id: id
      })
      .then((response) => {
        if(isDelete == true){
          this.sidebarDirectorData.id = response.id
        }else{
          this.count++;
          this.sidebarDirectorData.id = response.id
          this.sidebarDirectorData.school_id = response.school_id
          this.sidebarDirectorData.name = response.name
          this.sidebarDirectorData.cpf = response.cpf
          this.sidebarDirectorData.email = response.email
        }
      }) 
      .finally(() => {
      })
    },

    updateDetailSchoolAdmin(id, isDelete = false){
      this.updateDetailSchoolAdmin.id = id
    },

    newDeleteStatusDirector(value){
      this.DeleteStatusDirector = value
    },

    newDeleteStatusSchoolAdmin(value){
      this.DeleteStatusSchoolAdmin = value
    },

    deleteDirector(id){
      this.isLoading = true;
      let data = id
      this.$store
        .dispatch("directors/delete", data)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Excluído com sucesso!',
              icon: 'Trash2Icon',
              text: 'Operação executada com sucesso.',
              variant: 'danger'
            }
          })
          this.isLoading = false
          this.DeleteStatusDirector = false
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.sidebarDirectorData = {}
          this.fetchDetails()
          this.$refs['modal-delete-director'].hide()
        })
    },

    updateDetailTeacher(id) {
      this.sidebarTeacherData = {
        id: id
      }; 
      this.countTeacher++;
    },

    newDeleteStatusTeacher(value){
      this.DeleteStatusTeacher = value
    },

    deleteTeacher(id){
      this.isLoading = true;
      let data = {
        teacher_id : id,
        school_id : localStorage.getItem('school_id')
      }
      this.$store
        .dispatch("teachers/delete", data)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Excluído com sucesso!',
              icon: 'Trash2Icon',
              text: 'Operação executada com sucesso.',
              variant: 'danger'
            }
          })
          this.isLoading = false
          this.DeleteStatusTeacher = false
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.sidebarTeacherData = {}
          this.fetchDetails()
          this.$refs['modal-delete-teacher'].hide()
        })
    },

    deleteSchoolAdmin(id){
      this.isLoading = true;
      let data = id
      this.$store
        .dispatch("users/delete", data)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Excluído com sucesso!',
              icon: 'Trash2Icon',
              text: 'Operação executada com sucesso.',
              variant: 'danger'
            }
          })
          this.isLoading = false
          this.DeleteStatusTeacher = false
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.sidebarTeacherData = {}
          this.fetchDetails()
          this.$refs['modal-delete-school-admin'].hide()
        })
    },

    addNewAdmin() {
      this.sidebarAdminData = {
        school_id: this.$route.params.school_id,
      }; 
      this.countAdmin++;
    },

    addNewTeacher() {
      this.sidebarTeacherData = {
        school_id: this.$route.params.school_id
      }; 
    },

    addNewDirector() {
      this.sidebarDirectorData = {
        school_id: this.$route.params.school_id,
        company_id: this.schoolShow && this.schoolShow.company_id
      }; 
      this.count++;
    },
    addNewClass() {
      this.sidebarClassData = {
        school_id: this.$route.params.school_id,
        teachers: this.teachers
      }; 
      //this.countClass++;
    },
  },
  watch: {
    perPage(){
      //this.showBook()
    },
  }
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.bg-color-avatar {
  background: none !important;
}
</style>
