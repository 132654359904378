<template>
  <div>
    <b-sidebar
      id="sidebar-create-director"
      ref="sidebar-create-director"
      no-close-on-backdrop
      no-close-on-esc
      shadow
      right
      bg-variant="white"
      backdrop
      :visible=showSidebar
      :title="Object.entries(this.dataProp).length === 0 ? 'Cadastrar diretor' : 'Editar diretor'"
    >
      <div>
        <hr />
        <b-overlay :show="isLoadingStore" opacity="0.70" rounded="sm">
          <b-tabs content-class="mt-2" align="center">
            <b-tab active>
              <template #title>
                <span>Cadastrar</span>
              </template>
              <div>
                <b-form method="POST">
                  <validation-observer ref="simpleRules">
                  <b-form-input class="hidden" name="_token" :value="csrf" />
                  <b-form-group
                    label="* Escola:" 
                    label-for="i-school-director"
                    class="pl-1 pr-1"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="escola"
                      rules="required"
                    >
                      <b-form-select
                        id="i-school-director"
                        disabled
                        v-model="dataDirector.school_id"
                        :options="schools"
                        value-field="id"
                        text-field="name"
                        class="form-control"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label="Nome:"
                    label-for="i-name-director"
                    class="pl-1 pr-1"
                  >
                  <validation-provider
                      #default="{ errors }"
                      name="nome"
                      rules="required"
                    >
                    <b-form-input
                      id="i-name-director"
                      placeholder="Ex: Nome do Diretor"
                      v-model="dataDirector.name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label="CPF:"
                    label-for="i-cpf-director"
                    class="pl-1 pr-1"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="cpf"
                      rules="required"
                    >
                      <b-form-input
                        id="i-cpf-director"
                        v-mask="'###.###.###-##'"
                        placeholder="Ex: 012.345.678-99"
                        v-model="dataDirector.cpf"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label="email.:"
                    label-for="i-email-director"
                    class="pl-1 pr-1"
                  >
                  <validation-provider
                      #default="{ errors }"
                      name="email"
                      rules="required|email"
                    >
                    <b-form-input
                      id="i-email-director"
                      placeholder="Ex: email@provedor.com.br"
                      v-model="dataDirector.email"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label="Sou o gestor da escola:"
                    label-for="i-nota"
                    class="pl-1 pr-1"
                  >
                    <b-form-checkbox
                      checked="false"
                      class="custom-control-primary"
                      name="check-button"
                      switch
                      v-model="dataDirector.is_manager"
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                      </span>
                      <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                      </span>
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group
                    v-if="dataDirector.is_manager == true"
                    label="Senha:"
                    label-for="i-password-director"
                    class="pl-1 pr-1"
                  >
                  <validation-provider
                      #default="{ errors }"
                      name="password"
                      rules="required|password"
                    >
                    <b-form-input
                      type="password"
                      id="i-password-director"
                      placeholder="*************"
                      v-model="dataDirector.password"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
                  <b-row style="margin-right: 0rem !important;">
                    <b-col lg="3" sm="6" class="mr-1 ml-1">
                      <b-button
                        variant="relief-primary"
                        @click="storeDirector((value = 1))"
                        :disabled="dataDirector.name == null"
                      >
                        {{this.dataDirector.id == null ? 'Salvar' : 'Editar'}}
                      </b-button>
                    </b-col>
                    <b-col>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-danger"
                        @click="closeSidebar()"
                        block
                      >
                        Fechar
                      </b-button>
                    </b-col>
                  </b-row>
                  </validation-observer>
                </b-form>
              </div>
            </b-tab>
            <b-tab>
              <template #title>
                <span>Importar</span>
              </template>
              <div>
                <b-form method="POST">
                  <validation-observer ref="simpleRulesImport">
                    <b-form-input class="hidden" name="_token" :value="csrf" />
                    <b-form-group 
                      label="* Escola:" 
                      label-for="i-school-import-director"
                      class="pl-1 pr-1"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="escola"
                        rules="required"
                      >
                        <b-form-select
                          id="i-school-import-director"
                          v-model="dataImport.school_id"
                          disabled
                          :options="schools"
                          value-field="id"
                          text-field="name"
                          class="form-control"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Arquivo:"
                      label-for="i-file"
                      class="pl-1 pr-1"
                    >
                    <validation-provider
                        #default="{ errors }"
                        name="arquivo"
                        rules="required"
                      >
                      <b-form-file
                        placeholder="Arquivo"
                        v-model="dataImport.file"
                        type="file"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        name="file"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    </b-form-group>
                    <b-row style="margin-right: 0rem !important;">
                      <b-col sm="5" class="ml-1">
                        <b-button
                          variant="relief-primary"
                          @click="importDirector()"
                          :disabled="dataImport.school_id == null"
                          block
                        >
                          Importar
                        </b-button>
                      </b-col>
                      <b-col>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-danger"
                        @click="closeSidebar()"
                        block
                      >
                        Cancelar
                      </b-button>
                    </b-col>
                  </b-row>
                  </validation-observer>
                  <b-link class="mt-2 d-block text-center" href="/escola_e_diretores.xlsx" target="_blank">
                    <feather-icon icon="DownloadIcon" class="mr-1" />Baixar arquivo de exemplo
                  </b-link>
                </b-form>
              </div>
            </b-tab>
          </b-tabs>
        </b-overlay>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
/* eslint-disable */
import { BButton, BSidebar, VBToggle} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapState } from "vuex";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {mask} from 'vue-the-mask'
import {
  required, 
  email
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  props: { 
    dataProp: {
      type: Object,
      default: () => {}
    },
  },
  components: {
    BButton,
    BSidebar,
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
    mask,
  },
  data() {
    return {
      icon: null,
      dataImport: {
        school_id: null,
        file: null,
      },

      showSidebar: false,

      dataDirector: {
        school_id: null,
        name: null,
        cpf: null,
        email: null,
        is_manager: false,
        password: null,
      },
      isLoadingInep: false,
      isLoadingStore: false,
      csrf: document.head.querySelector('meta[name="csrf-token"]') ? document.head.querySelector('meta[name="csrf-token"]').content : '',
    }
  },
  computed: {
    ...mapState("schools", ["schools"]),
  },
  methods: {
    ...mapActions("directors", ["store", "update", "import"]),
    fetchDirectors(paginated = true, page = 1){
      this.isLoading = true
      this.$store
        .dispatch("directors/fetchDirectors", {
          paginate: paginated,
          page: page,
          generic: this.search,
          per_page: this.perPage
        })
        .then((response) => {
          this.totalRows = response.total
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    importDirector(){
      this.$refs.simpleRulesImport.validate().then(success => {
        if (success) {
          this.isLoadingStore = true
          this.import(this.dataImport).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Importado com sucesso!',
                icon: 'ThumbsUpIcon',
                text: 'Operação executada com sucesso.',
                variant: 'success'
              }
            })
            this.dataImport.school_id = null;
            this.dataImport.file = null;
            this.$nextTick(() => this.$refs.simpleRulesImport.reset());
            this.fetchDirectors(); 
          }).finally(() => {
            this.$emit('fetch-details', { 
                school_id: this.dataDirector.school_id,
            });
            this.isLoadingStore = false
            this.closeSidebar()
          })
        }
      });
    },
    storeDirector(value) {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.isLoadingStore = true
          if (this.dataDirector.id == null) { 
            this.store(this.dataDirector).then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Cadastrado com sucesso!',
                  icon: 'ThumbsUpIcon',
                  text: 'Operação executada com sucesso.',
                  variant: 'success'
                }
              })
              this.$emit('fetch-details', { 
                school_id: this.dataDirector.school_id,
              });
              this.dataDirector.id = null;
              this.dataDirector.school_id = null;
              this.dataDirector.name = null;
              this.dataDirector.cpf = null;
              this.dataDirector.email = null;
              this.is_manager = false,
              this.password = null
              this.$nextTick(() => this.$refs.simpleRules.reset());
            })
            .finally(() => {
              this.isLoadingStore = false;
              this.closeSidebar()
            })
          } else {
            this.update(this.dataDirector).then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Atualizado com sucesso!',
                  icon: 'ThumbsUpIcon',
                  text: 'Operação executada com sucesso.',
                  variant: 'info'
                }
              })

              this.dataDirector.id = null;
              this.dataDirector.school_id = null;
              this.dataDirector.name = null;
              this.dataDirector.cpf = null;
              this.dataDirector.email = null;
              this.$nextTick(() => this.$refs.simpleRules.reset());
            })
            .finally(() => {
              this.$emit('fetch-details', { 
                school_id: this.dataDirector.school_id,
              });
              this.isLoadingStore = false
              this.closeSidebar();
            })
          }
        }else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro de validação!',
              icon: 'AlertTriangleIcon',
              text: 'Por favor preencha todos os campos obrigatorios.',
              variant: 'danger'
            }
          })
        }
      })
    },

    fetchSchools(paginated = false, page = 1){
      this.isLoading = true
      this.$store
        .dispatch("schools/fetchSchools", {
          paginated: paginated,
          page: page,
          generic: this.search,
          per_page: this.perPage
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    closeSidebar(){
      this.$refs['sidebar-create-director'].hide()
    }
  },
  watch: {
  dataProp: {
    // the callback will be called immediately after the start of the observation
    immediate: true, 
    handler (val, oldVal) {
      if (!val) return; 
      if (Object.entries(this.dataProp).length === 0 || this.dataProp.id == null) { 
        this.dataDirector.school_id = this.dataProp.school_id;
         this.dataImport.school_id = this.dataProp.school_id;
        //this.initValues(); 
        //this.$validator.reset(); 
      } else { 
        let { id, school_id, name, cpf, email } = JSON.parse(JSON.stringify(this.dataProp));
        this.dataDirector.id = id;
        this.dataDirector.school_id = school_id;
        this.dataDirector.name = name;
        this.dataDirector.cpf = cpf;
        this.dataDirector.email = email;


        this.showSidebar = true;

        this.dataImport.school_id = school_id;
        //this.initValues(); 
      } 
    }
  }
  },
  created() {
    this.fetchSchools();
  },
}
</script>