<template>
  <div>
    <b-sidebar
      id="sidebar-create-teacher"
      ref="sidebar-create-teacher"
      shadow
      right
      bg-variant="white"
      backdrop
      no-close-on-backdrop
      no-close-on-esc
      :title="dataProp.id == null ? 'Cadastrar Professor' : 'Editar Professor'"
    >
      <div>
        <hr>
          <b-overlay :show="isLoadingStore" opacity="0.70" rounded="sm">
            <b-tabs content-class="mt-2" align="center">
              <b-tab active>
                <template #title>
                  <span>{{dataProp.id == null ? 'Cadastrar' : 'Editar'}}</span>
                </template>
                <validation-observer ref="simpleRules">
                   <b-form enctype="multipart/form-data" method="POST">
                   <!-- CPF -->
                    <b-form-group
                      label="CPF (Usuário):"
                      label-for="i-cpf-teacher"
                      class="pl-1 pr-1"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="CPF"
                        rules="required"
                      >
                        <b-form-input
                          id="i-cpf-teacher"
                          placeholder="123.456.789-01"
                          v-mask="'###.###.###-##'"
                          v-model="dataTeacher.cpf"
                          @change="fetchTeacherSchools"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-overlay :show="isLoadingCpf" opacity="0.70" rounded="sm" variant="transparent">
                  <!-- Nome -->
                    <b-form-group
                      label="Nome:"
                      label-for="i-nome"
                      class="pl-1 pr-1"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="nome"
                        rules="required"
                      >
                        <b-form-input
                          id="i-nome"
                          placeholder="Nome do Professor"
                          v-model="dataTeacher.name"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <!-- Email -->
                    <b-form-group
                      label="Email:"
                      label-for="i-email-teacher"
                      class="pl-1 pr-1"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="email"
                        rules="required|email"
                      >
                        <b-form-input
                          id="i-email-teacher"
                          placeholder="professor@escola.com.br"
                          v-model="dataTeacher.email"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <!-- Senha -->
                    <b-form-group
                      label="Senha:"
                      label-for="i-password"
                      class="pl-1 pr-1"
                      v-if="dataProp.id == null"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="senha"
                      >
                        <b-form-input
                          id="i-password"
                          type="password"
                          placeholder="************"
                          :disabled="blockPassword == true"
                          v-model="dataTeacher.password"
                          :state="errors.length > 0 ? false:null"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    </b-overlay>
                  </b-form>
                  <b-row style="margin-right: 0rem !important;" class="ml-1 mr-1">
                    <!-- <b-col class="ml-1 mr-1"> -->
                      <b-button
                        variant="relief-primary"
                        block
                        class="mr-1"
                        @click="storeTeacher"
                      >
                        <span>{{dataProp.id == null ? 'Incluir Professor' : 'Editar'}}</span>
                      </b-button>
                    <!-- </b-col> -->
                    <!-- <b-col> -->
                      <b-button 
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-danger"
                        @click="closeSidebar"
                        class="mr-1"
                        block
                      >
                        Fechar
                      </b-button>
                    <!-- </b-col> -->
                  </b-row>
                </validation-observer>
              </b-tab>
              <b-tab>
                <template #title>
                  <span>Importar</span>
                </template>
                <b-form method="POST">
                <validation-observer ref="simpleRulesImport">
                  <b-form-input class="hidden" name="_token" :value="csrf" />
                  <b-form-group
                    label="Arquivo:"
                    label-for="i-file"
                    class="pl-1 pr-1"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="arquivo"
                      rules="required"
                    >
                      <b-form-file
                        placeholder="Arquivo"
                        v-model="dataImport.file"
                        type="file"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        name="file"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-row style="margin-right: 0rem !important;">
                      <b-col sm="5" class="ml-1">
                        <b-button
                          variant="relief-primary"
                          @click="importTeachers()"
                          :disabled="dataImport.file == null"
                          block
                        >
                          Importar
                        </b-button>
                      </b-col>
                      <b-col>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-danger"
                        @click="closeSidebar()"
                        block
                      >
                        Cancelar
                      </b-button>
                    </b-col>
                  </b-row>
                </validation-observer>
                <b-link class="mt-2 d-block text-center" href="/professores.xlsx" target="_blank">
                  <feather-icon icon="DownloadIcon" class="mr-1" />Baixar arquivo de exemplo
                </b-link>
                </b-form>
              </b-tab>
            </b-tabs>
        </b-overlay>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
/* eslint-disable */
import { BButton, BSidebar, VBToggle} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapState } from "vuex";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, confirmed, password } from '@validations'

export default {
  components: {
    BButton,
    BSidebar,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  props: { 
    dataProp: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {

      isLoadingStore: false,

      dataImport: {
        file: null,
        school_id: null,
      },

      isLoadingCpf: false,

      isExisting: false,

      blockPassword: false,

      dataTeacher: {
        id: null,
        name: null,
        email: null,
        username: null,
        password: null,
        school_id: null,
        cpf: null,
      },

      addTeacherSchool: {
        teacher_id: null,
        school_id: null,
      },

      csrf: document.head.querySelector('meta[name="csrf-token"]') ? document.head.querySelector('meta[name="csrf-token"]').content : '',
    }
  },
  computed: {
    ...mapState("teachers", ["teachers", "teacherSchool"]),
  },
  created() {
   
  },
  methods: {
    ...mapActions("teachers", ["store", "update", "import", "storeSchoolTeacher"]),
    storeTeacher(){
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.isLoading = true;
          if(this.dataTeacher.id == null) {
            if(this.isExisting == false) {
              this.dataTeacher.username = this.dataTeacher.cpf;
              this.store(this.dataTeacher).then(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Cadastrado com sucesso!',
                    icon: 'ThumbsUpIcon',
                    text: 'Operação executada com sucesso.',
                    variant: 'success'
                  }
                })
                this.$emit('fetch-details', { 
                  school_id: this.dataTeacher.school_id,
                });
                this.initValues();
              })
              .finally(() => {
                this.isLoadingStore = false
                this.closeSidebar()
              })
            }else{
              this.storeSchoolTeacher(this.addTeacherSchool).then(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Adicionado com sucesso!',
                    icon: 'ThumbsUpIcon',
                    text: 'Operação executada com sucesso.',
                    variant: 'success'
                  }
                })
                this.$emit('fetch-details', { 
                  school_id: this.dataTeacher.school_id,
                });
                this.initValues();
              })
              .finally(() => {
                this.isLoadingStore = false
                this.closeSidebar()
              })
            }
          }else{
            this.dataTeacher.username = this.dataTeacher.cpf;
            this.update(this.dataTeacher).then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Atualizado com sucesso!',
                  icon: 'ThumbsUpIcon',
                  text: 'Operação executada com sucesso.',
                  variant: 'info'
                }
              })
              this.initValues();
            })
            .finally(() => {
              this.$emit('fetch-details', { 
                school_id: this.dataTeacher.school_id,
              });
              this.isLoadingStore = false
              this.closeSidebar();
            })
          }
        }else{
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro de validação!',
              icon: 'AlertTriangleIcon',
              text: 'Por favor preencha todos os campos obrigatorios.',
              variant: 'danger'
            }
          })
        }
      })
    },

    fetchTeacherSchools(){
      this.isLoadingCpf = true
      this.$store
        .dispatch("teachers/showTeacherSchool", {
          cpf_teacher: this.dataTeacher.cpf,
          by_cpf: true
          })
        .then((response) => {
          if(response == 'Professor não encontrado'){
            this.blockPassword = false
            this.isExisting = false
            let cpf = this.dataTeacher.cpf;
            this.initValues();
            this.dataTeacher.cpf = cpf;
          }else{
            this.isExisting = true
            this.blockPassword = true
            this.dataTeacher.name = response.name
            this.dataTeacher.email = response.user.email
            this.addTeacherSchool.teacher_id =  response.id
            this.addTeacherSchool.school_id = this.$route.params.school_id
          }
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.isLoadingCpf = false
        })
    },

    importTeachers(){
      this.$refs.simpleRulesImport.validate().then(success => {
        if (success) {
          this.isLoadingStore = true
          this.import(this.dataImport).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Importado com sucesso!',
                icon: 'ThumbsUpIcon',
                text: 'Operação executada com sucesso.',
                variant: 'success'
              }
            })
            this.dataImport.school_id = null;
            this.dataImport.file = null;
            this.$nextTick(() => this.$refs.simpleRulesImport.reset());
          }).finally(() => {
            this.$emit('fetch-details', { 
              school_id: this.dataImport.school_id,
            });
            this.isLoadingStore = false
            this.closeSidebar()
          })
        }
      });
    },

    closeSidebar(){
      this.$refs['sidebar-create-teacher'].hide()
    },

    initValues(){
      this.dataTeacher.name = null;
      this.dataTeacher.email = null;
      this.dataTeacher.username = null;
      this.dataTeacher.password = null;
      //this.dataTeacher.school_id = null; Não pode zerar aqui para não perder o id que vei por parâmetro
      this.dataTeacher.id = null;
      this.dataTeacher.cpf = null;
      this.$nextTick(() => this.$refs.simpleRules.reset());
    }
  },
  watch: {
    dataProp: {
      immediate: true, 
      handler (val, oldVal) {
        if (!val) return; 
          this.initValues(); 
          this.dataTeacher.school_id = this.dataProp.school_id;
          this.dataImport.school_id = this.dataProp.school_id;
          if(this.dataProp.id){
            let dataModel = this.$store.getters['schools/getSchoolTeacherById'](this.dataProp.id)
            this.dataTeacher.id = dataModel.id;
            this.dataTeacher.name = dataModel.user.name;
            this.dataTeacher.email = dataModel.user.email;
            this.dataTeacher.school_id = dataModel.school_id;
            this.dataImport.school_id = dataModel.school_id;
            this.dataTeacher.username = dataModel.user.username;
            this.dataTeacher.cpf = dataModel.cpf;
          }
      }
    }
  },
}
</script>